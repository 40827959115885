<template>
  <div class="loginContainer">
    <div :class="bodySize?'containers':'containersMax'">
      <div class="login_left">
        <div class="qrCode"></div>

        <div class="forms">
          <div class="form_tabs">
            <div>
              <p :class="activeName=='first'?'login_tab':''" @click="activeName='first'">
                <span>密码登录</span>
              </p>
            </div>
            <div>
              <p :class="activeName=='second'?'login_vali_tab':''" @click="activeName='second'">
                <span>验证码登录</span>
              </p>
            </div>
          </div>
        </div>
        <div class="form_item">
          <el-form :model="form" ref="form">
            <el-form-item
              prop="mobile"
              :rules="[{ required: true, message: '请输入您的手机号', trigger: 'blur' },{ pattern:/^(?:(?:\+|00)86)?1[3-9]\d{9}$/,message: '请输入正确的手机号格式',trigger: 'blur'}]"
            >
              <el-input v-model="form.mobile" placeholder="请输入您的手机号">
                <template slot="prepend">
                  <i class="el-icon-user-solid"></i>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item
              prop="password"
              v-show="activeName=='first'"
              :rules="{ required: true, message: '请输入您的密码', trigger: 'blur' }"
            >
              <el-input v-model="form.password" placeholder="请输入您的密码" type="password" show-password>
                <template slot="prepend">
                  <i class="iconfont icon-mima"></i>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item
              prop="code"
              v-show="activeName=='second'"
              :rules="{ required: true, message: '请输入您的验证码', trigger: 'blur' }"
            >
              <el-input v-model="form.code" placeholder="请输入您的验证码">
                <template slot="append">
                  <el-button style="color:#5e72f9;width:110px" @click="getCode">{{btnText}}</el-button>
                </template>
              </el-input>
            </el-form-item>

            <el-form-item>
              <Slide v-on:confirmSuccess="dragBtn"></Slide>
            </el-form-item>
            <el-form-item v-show="activeName=='first'">
              <el-button type="primary" @click="onSubmit" style="width:100%;height:50px">登录</el-button>
              <div class="form_link">
                <el-link :underline="false" @click="toRegister">免费注册</el-link>
                <el-link :underline="false" @click="toForget">忘记密码</el-link>
              </div>
            </el-form-item>

            <el-form-item v-show="activeName=='second'">
              <el-button type="primary" @click="onSubmitCode" style="width:100%;height:50px">登录</el-button>
              <div class="form_link">
                <el-link :underline="false" @click="toRegister">免费注册</el-link>
                <el-link :underline="false" @click="toForget">忘记密码</el-link>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="login_right" v-show="!bodySize">
        <div>
          <img src="../assets/logo.png" width="90" height="90" />
        </div>
        <div>
          <img src="../assets/login/logn_r_middle.png" height="90" />
        </div>
        <div class="img_r_bottom">
          <img src="../assets/login/酒店1.png" width="60" />
          <img src="../assets/login/酒店2.png" width="60" />
          <img src="../assets/login/酒店3.png" width="60" />
          <img src="../assets/login/酒店4.png" width="60" />
          <img src="../assets/login/酒店5.png" width="60" />
          <img src="../assets/login/酒店6.png" width="60" />
          <img src="../assets/login/酒店7.png" width="60" />
          <img src="../assets/login/酒店8.png" width="60" />
        </div>
        <div class="login_r_bottom">
          <el-link :underline="false" href="https://www.housky.cn/" target="_blank">
            查看更多案例
            <i class="el-icon-arrow-right"></i>
          </el-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getLoginApi, getCodeApi, getLoginCodeApi } from "../api/data";
import Slide from "../components/Slide";
import Aes from "../utils/aes";
export default {
  components: {
    Slide
  },
  data() {
    return {
      form: {
        mobile: "",
        password: "",
        code: ""
      },
      activeName: "first",
      confirmSuccess: false,
      btnText: "获取验证码",
      timer: null,
      bodySize: false
    };
  },
  created() {
    // this.wathBtn();
    this.wathSize();
  },
  methods: {
    //监听键盘enter事件
    wathBtn() {
      document.addEventListener("keydown", () => {
        let key = window.event.keyCode;
        if (this.activeName == "first") {
          if (key == 13) this.onSubmit();
        } else {
          if (key == 13) this.onSubmitCode();
        }
      });
    },
    //监听当前窗口大小
    wathSize() {
      if (document.body.clientWidth <= 992) {
        this.bodySize = true;
      } else {
        this.bodySize = false;
      }
      window.addEventListener("resize", () => {
        //宽度小于1190收缩左侧菜单栏
        if (document.body.clientWidth <= 992) {
          this.bodySize = true;
        } else {
          this.bodySize = false;
        }
      });
    },

    //密码登录module
    onSubmit() {
      if (this.form.username != "" && this.form.password != "") {
        if (this.confirmSuccess) {
          getLoginApi(this.form).then(res => {
            if (res) {
              //将用户token信息存入vuex中
              this.$store.commit("setAuthorization", res.msg);
              this.$store.commit(
                "setUserInfo",
                Aes.encrypt(JSON.stringify(res.data))
              ); //保存用户信息
              this.$router.push("/placesList");
            }
          });
        } else {
          this.$message.warning("请向右滑动");
        }
      } else {
        this.$message.warning("请输入用户名和密码");
      }
    },
    //滑动模块
    dragBtn(val) {
      this.confirmSuccess = val;
    },

    // 验证码登录module
    //发送验证码
    getCode() {
      if (this.form.mobile) {
        if (this.timer == null) {
          getCodeApi({ mobile: this.form.mobile }).then(res => {
            if (res) this.$message.success(res.msg);
          });
        }
      } else {
        this.$message.warning("请输入您的手机号");
        return false;
      }

      const TIME_COUNT = 60;
      if (!this.timer) {
        this.btnText = TIME_COUNT;
        this.timer = setInterval(() => {
          if (this.btnText > 0 && this.btnText <= TIME_COUNT) {
            this.btnText--;
          } else {
            clearInterval(this.timer);
            this.timer = null;
            this.btnText = "获取验证码";
          }
        }, 1000);
      }
    },

    //验证码登录
    onSubmitCode() {
      if (this.form.mobile != "" && this.form.code != "") {
        if (this.confirmSuccess) {
          getLoginCodeApi(this.form).then(res => {
            if (res) {
              //将用户token信息存入vuex中
              this.$store.commit("setAuthorization", res.msg);
              this.$store.commit(
                "setUserInfo",
                Aes.encrypt(JSON.stringify(res.data))
              ); //保存用户信息
              this.$router.push("/placesList");
            }
          });
        } else {
          this.$message.warning("请向右滑动");
        }
      } else {
        this.$message.warning("请输入手机号和验证码");
      }
    },
    toRegister() {
      this.$router.push("/register");
    },
    toForget() {
      this.$router.push("/forgetPassWord");
    }
  }
};
</script>
<style lang="scss">
.loginContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100%;
  background: url("../assets/login/login_home.jpg") center no-repeat;
  background-size: cover;
  text-align: center;
  position: relative;
}

.containers {
  width: 500px;
  height: 500px;
  margin: auto;
  display: flex;
  .login_left {
    border-radius: 10px;
  }
}
.containersMax {
  width: 1000px;
  height: 500px;
  margin: auto;
  display: flex;
}

.login_left {
  background: #ffffff;
  width: 100%;
  height: 100%;
  border-radius: 10px 0 0 10px;

  .qrCode {
    height: 60px;
    width: 60px;
    border-radius: 10px 0 0 0;
    background: url("../assets/login/login_qrcode.png") no-repeat;
    background-position: -60px 0px;
    background-size: 120px 120px;
    cursor: pointer;
  }
  .qrCode:hover {
    background-position: 0px 0px;
  }
  .forms {
    .form_tabs {
      display: flex;
      justify-content: space-around;
      margin: 0 50px;
      height: 30px;
      border-bottom: 1px solid #f3f3f3;
      position: relative;
      letter-spacing: 1px;
      font-size: 18px;
      p:hover {
        // font-size: 22px;
        // line-height: 22px;
        cursor: pointer;
        color: #5e72f9;
      }

      .login_tab {
        font-weight: bold;
        color: #5e72f9;
      }

      .login_tab::before {
        content: "";
        position: absolute;
        width: 40px;
        height: 2px;
        left: 19%;
        bottom: 0px;
        background: #5e72f9;
      }
      .login_vali_tab {
        font-weight: bold;
        color: #5e72f9;
      }
      .login_vali_tab::before {
        content: "";
        position: absolute;
        width: 40px;
        height: 2px;
        left: 69%;
        bottom: 0px;
        background: #5e72f9;
      }
    }
  }
  .form_item {
    margin: 50px 50px 0px 50px;
    .form_link {
      display: flex;
      justify-content: space-between;
      .el-link {
        font-size: 12px;
      }
    }
    .el-form-item {
      margin-bottom: 30px;
      .el-input__inner {
        height: 50px;
        line-height: 50px;
      }
    }
  }
}
.login_right {
  background: rgba($color: #38466f, $alpha: 0.8);
  width: 100%;
  height: 100%;
  border-radius: 0 10px 10px 0;
  padding-top: 10px;
  .img_r_bottom {
    margin: 20px 20px 0 60px;
    img {
      margin: 10px 40px 0 0;
    }
  }
  .login_r_bottom {
    padding-top: 50px;
    .el-link {
      color: #ffffff;
      font-size: 12px;
    }
    .el-link:hover {
      color: #5e72f9;
    }
  }
}
</style>
